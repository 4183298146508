import React from "react"

import dataExport from "../images/export.png"
import idea from "../images/idea.png"
import phone from "../images/phone.png"
import setup from "../images/setup.png"
import otherPhone from "../images/other-phone.png"
import SectionHeader from "./section-header"
import { COLORS } from "../styles/constants"

const Content = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        margin: "4rem 0",
      }}
    >
      <div>
        <img src={setup} alt="guy checking a list" />
      </div>
      <div>
        <h3>Simple account setup</h3>
        <p style={{ color: COLORS.gray }}>
          Scan the QR and you're all set.<br/>You can type the details manually if you don't want to grant camera access.
        </p>
      </div>
    </content>
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        margin: "4rem 0",
      }}
    >
      <div>
        <h3>Your data, you're in control</h3>
        <p style={{ color: COLORS.gray }}>
          The data never leaves your device.<br/>Zero magic behind the scenes.
        </p>
      </div>
      <div>
        <img src={phone} alt="a hand holding a phone" />
      </div>
    </content>
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        margin: "4rem 0",
      }}
    >
      <div>
        <img src={otherPhone} alt="another phone" />
      </div>
      <div>
        <h3>Local authentication</h3>
        <p style={{ color: COLORS.gray }}>
          Support for FaceID / TouchID
        </p>
      </div>
    </content>
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        margin: "4rem 0",
      }}
    >
      <div>
        <h3>Fast and simple data export</h3>
        <p style={{ color: COLORS.gray }}>
          Zero lock-in. Export your data in an instant.
        </p>
      </div>
      <div>
        <img src={dataExport} alt="finger and shapes" />
      </div>
    </content>
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
        margin: "4rem 0",
      }}
    >
      <div>
        <img src={idea} alt="guy having an idea" />
      </div>
      <div>
        <h3>Open source</h3>
        <p style={{ color: COLORS.gray }}>
          Owky is FOSS (free and open source software)<br/>You can find the repo <a href={"https://github.com/charlietango/owky"} target={"_blank"}>here</a>.
        </p>
      </div>
    </content>
  </div>
)

export default Content
