import React from "react"
import { COLORS } from "../styles/constants"
import appStore from "../images/appstore.png"

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <h2>Zero Ads. Zero nonsense</h2>
    <p style={{ color: COLORS.accent }}>
      Owky is and will always stay free and open source. Where's the catch? Well, there's no catch.<br/>
      I'm a <a href={"https://twitter.com/charlietango592"} target={'_blank'}>real dude</a>, building this for myself first, and I want to share it with other people.<br />
      If you find it useful, you can <a href={"https://github.com/charlietango/owky/issues"} target={'_blank'}>contribute to the codebase ⤴︎</a> or <a href={"https://www.buymeacoffee.com/charlietango8"} target={'_blank'}>buy me a coffee ⤴︎</a>.<br />
      Oh, and if you have an idea to make it better please <a href="mailto:catalin@charlietango.co">get in touch</a>.
    </p>
    <a href={"https://apps.apple.com/app/owky-two-factor-authenticator/id1602245257"} target={"_blank"}>
      <img  className={"button__app-store"} src={appStore} alt={"AppStore badge"}/>
    </a>
  </div>
)

export default CallToAction
