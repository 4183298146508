import React from "react"
import PropTypes from "prop-types"

import headerImage from "../images/header.png"
import appStore from "../images/appstore.png"
import MockupContent from "./image"

const Header = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>Owky - helps you stay secure</h1>
    <p style={{ textAlign: "center", maxWidth: 500 }}>
      Owky is a <b>free and open source</b> Two Factor Authentication (2FA) application, which generates Time-based One-Time Passwords (TOTP).
    </p>
    <a href={"https://apps.apple.com/app/owky-two-factor-authenticator/id1602245257"} target={"_blank"}>
      <img  className={"button__app-store"} src={appStore} alt={"AppStore badge"}/>
    </a>
    <div style={{ margin: '100px 60px 0 60px', width: '100%', maxWidth: `950px`, position: "relative" }}>
      <MockupContent />
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
