import React from "react"

import { COLORS } from "../styles/constants"

const SectionHeader = () => (
  <>
    <h2>Why use Owky?</h2>
    <p style={{ color: COLORS.accent }}>
      Owky doesn't get in your way. It helps you secure your online accounts, without compromise. <br />
      You can <b>see</b> your data isn't leaving your device, because the app is open source. You can also export your data in an instant<br />
      so there's no lock-in. You can see all the app features bellow.
    </p>
  </>
)

export default SectionHeader
